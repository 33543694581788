// eslint-disable-next-line camelcase
import tipoAfiliado from './tipo_afiliado'
import nomCountries from './nom_countries'
import nomCurrencies from './nom_currencies'
import categoriesConditions from './categories_conditions'
import suplementosCar from './cars/suplementos'
import condicionesGeneralesCar from './cars/condiciones_generales'
import transmision from './cars/transmision'
import typeAuto from './cars/type'
import marca from './cars/marca'
import modelo from './cars/modelo'
import combustible from './cars/combustible'
import caracteristicas from './cars/caracteristicas'
import ptoRecogida from './cars/ptoRecogida'
import categories from './cars/categories'
import rentadoras from './cars/rentadoras'
import tarifasCars from './cars/tarifas'
import tarifasXKmCars from './cars/tarifasXKm'
import edades from './cars/edades'
import tagsImgCar from './cars/tagsImg'
import oficinasRenta from './cars/oficinasRenta'

import tti from './flights/tti'
import aircraft from './flights/aeronaves'
import suplementosFlight from './flights/suplementos'
import condicionesGeneralesFlight from './flights/condiciones_generales'
import aerolineas from './flights/aerolineas'
import airports from './flights/airports'
import tarjetaFidelidad from './flights/tarjeta_fidelidad'
import codeOfac from './flights/code_ofac'
import tarifas from './flights/tarifas'
import dimensiones from './flights/dimensiones'
import pesos from './flights/pesos'
import clases from './flights/clases'
import politicaTarifaria from './flights/politica_tarifaria'

import tipoDestino from './hotels/tipoDestinos'
import suplementosHotel from './hotels/suplementos'
import reduccionesHotel from './hotels/reducciones'
import condicionesGeneralesHotel from './hotels/condiciones_generales'
import operador from './hotels/operador'
import marcaAsociada from './hotels/marcaAsociada'
import categorias from './hotels/categorias'
import intereses from './hotels/intereses'
import servicios from './hotels/servicios'
import habitaciones from './hotels/habitaciones'
import regimenAlimenticio from './hotels/regimen_alimenticio'
import categoriesTarifarios from './hotels/categories_tarifarios'
import edad from './hotels/edad'
import tagsImg from './hotels/tagsImg'

// eslint-disable-next-line camelcase
export default [
  ...categoriesConditions,
  ...tipoAfiliado,
  ...nomCountries,
  ...nomCurrencies,
  ...suplementosCar,
  ...condicionesGeneralesCar,
  ...transmision,
  ...typeAuto,
  ...marca,
  ...modelo,
  ...combustible,
  ...caracteristicas,
  ...ptoRecogida,
  ...categories,
  ...rentadoras,
  ...tarifasCars,
  ...tarifasXKmCars,
  ...edades,
  ...tagsImgCar,
  ...oficinasRenta,

  ...tti,
  ...aircraft,
  ...suplementosFlight,
  ...condicionesGeneralesFlight,
  ...aerolineas,
  ...airports,
  ...tarjetaFidelidad,
  ...codeOfac,
  ...tarifas,
  ...dimensiones,
  ...pesos,
  ...clases,
  ...politicaTarifaria,

  ...tipoDestino,
  ...suplementosHotel,
  ...reduccionesHotel,
  ...condicionesGeneralesHotel,
  ...operador,
  ...marcaAsociada,
  ...categorias,
  ...intereses,
  ...servicios,
  ...habitaciones,
  ...regimenAlimenticio,
  ...categoriesTarifarios,
  ...edad,
  ...tagsImg,
]
