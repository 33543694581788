<template>
  <v-menu
    v-if="!isLoading"
    offset-y
    nudge-bottom="22"
    min-width="175"
    left
    :elevation="$vuetify.theme.dark ? 9 : 8"
  >
    <!-- Activator -->
    <template v-slot:activator="{ on, attrs }">
      <div
        v-bind="attrs"
        class="d-flex align-center"
        v-on="on"
      >
        <span class="ml-4 mr-1">{{ currency_symbol }}</span>
        <span v-if="$vuetify.breakpoint.smAndUp">{{ currencies.find(l => l.code === currency_code).code }}</span>
      </div>
    </template>

    <!-- Options List @change="updateActiveLocale" -->
    <v-list>
      <v-list-item-group>
        <v-list-item
          v-for="currency in currencies"
          :key="currency.code"
          :value="currency.id"
          @click="setChangeCurrency(currency)"
        >
          <v-list-item-title>{{ currency.symbol }} {{ currency.code }}</v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-title><a>{{ $t('lbl.showMoreFilters') }}</a></v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  data() {
    return {
      isLoading: true,
      currencies: [],
    }
  },
  computed: {
    ...mapState({
      currency_code: state => state.app.currency_code,
      currency_symbol: state => state.app.currency_symbol,
    }),
    availableLocales() {
      return this.currencies.filter(i => i.code !== this.currency_code)
    },
  },

  created() {
    this.getCurrencies()

    if (localStorage.getItem('currency_code') !== null) {
      this.setCurrencyCode(localStorage.getItem('currency_code'))
    }
    if (localStorage.getItem('currency_symbol') !== null) {
      this.setCurrencySymbol(localStorage.getItem('currency_symbol'))
    }
  },
  methods: {
    ...mapMutations([
      'setCurrencyCode',
      'setCurrencySymbol',
    ]),
    setChangeCurrency(currency) {
      localStorage.setItem('currency_code', currency.code)
      localStorage.setItem('currency_symbol', currency.symbol)

      this.setCurrencyCode(localStorage.getItem('currency_code'))
      this.setCurrencySymbol(localStorage.getItem('currency_symbol'))

      const json = {
        currency_id: currency.id,
        currency_code: this.currency_code,
        currency_symbol: this.currency_symbol,
      }
      this.axios
        .post('profile/update-data', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          if (response.data.success === false) { }
        })
    },
    getCurrencies() {
      this.axios.get('currency_list?per_page=10000').then(res => {
        const curr = res.data.data
        curr.forEach(element => {
          if (element.active) {
            this.currencies.push(element)
          }
        })
      })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>
